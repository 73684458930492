const ShopList = [
  {
    name: 'MLB café FUKUOKA', // 店名
    description: 'アメリカンダイニング＆スポーツバー', // 説明
    hvImg: 'main-mlbcafe04.jpg', // 画像名
    img: 'main-mlbcafe05.jpg', // ホバー画像名
    link: '/the-foodhall/mlbcafe-fukuoka/', // リンク先
  },
  {
    name: 'ラーメン暖暮×らーめん酒場暖暮', // 店名
    description: 'ラーメン、一品料理、ドリンク', // 説明
    hvImg: 'main-danbo.jpg', // 画像名
    img: 'main-danbo2.jpg', // ホバー画像名
    link: '/the-foodhall/danbo/', // リンク先
  },
  {
    name: '焼肉 赤身 にくがとう', // 店名
    description: '焼肉、肉まぶし、肉料理', // 説明
    link: '/the-foodhall/nikugatou/', // リンク先
    hvImg: 'main-nikugatou.jpg', // 画像名
    img: 'menu-nikugatou.png', // ホバー画像名
    label: '九州初出店', // ラベル
    takeout: true,
    takeoutUrl: '/files/food/takeout-nikugatou.pdf',
  },
  {
    name: '京都 天ぷら 花いかだ', // 店名
    description: '天ぷら、天丼', // 説明
    link: '/the-foodhall/hanaikada/', // リンク先
    hvImg: 'main-hanaikada.jpg', // 画像名
    img: 'menu-hanaikada.png', // ホバー画像名
    label: '全国初出店※', // ラベル
    uber: true,
  },
  {
    name: '九州 はかた 大吉寿司', // 店名
    description: '回転寿司', // 説明
    link: '/the-foodhall/daikichi/', // リンク先
    hvImg: 'main-daikichi.jpg', // 画像名
    img: 'menu-daikichi.png', // ホバー画像名
    label: '全国初出店※', // ラベル
    takeout: true,
    uber: true,
    takeoutUrl: '',
  },
  {
    name: '肉山食堂', // 店名
    description: '赤身肉料理', // 説明
    link: '/the-foodhall/nikuyama/', // リンク先
    hvImg: 'main-nikuyama.jpg', // 画像名
    img: 'main-nikuyama2.jpg', // ホバー画像名
    label: '全国初出店※', // ラベル
    takeout: true,
    takeoutUrl: '',
    uber: false,
    uberUrl: '',
  },
  // {
  //   name: 'TRATTORIA IL LUPINO', // 店名
  //   description: 'イタリアン', // 説明
  //   descriptionAttention: '現在休業中です。詳しくはこちら。', // 注意文言
  //   descriptionAttentionLink: '/news/the-foodhall/202012291591/', // 注意文言リンク
  //   link: '/the-foodhall/illupino/', // リンク先
  //   hvImg: 'main-illupino.jpg', // 画像名
  //   img: 'menu-illupino.png', // ホバー画像名
  //   label: '日本初出店', // ラベル
  //   greyOut: true, // 画像グレーアウト
  // },
  // {
  //   name: 'WINE BAR IL LUPINO', // 店名
  //   description: 'ワイン、カクテル', // 説明
  //   descriptionAttention: '現在休業中です。詳しくはこちら。', // 注意文言
  //   descriptionAttentionLink: '/news/the-foodhall/202012291591/', // 注意文言リンク
  //   link: '/the-foodhall/illupino-bar/', // リンク先
  //   hvImg: 'main-illupino-bar.jpg', // 画像名
  //   img: 'menu-illupino-bar.png', // ホバー画像名
  //   label: '日本初出店', // ラベル
  //   greyOut: true, // 画像グレーアウト
  // },
  {
    name: 'ファイヤーホール4000', // 店名
    description: '火鍋、中華料理', // 説明
    link: '/the-foodhall/firewhole/', // リンク先
    hvImg: 'main-firewhole2.jpg', // 画像名
    img: 'main-firewhole1.jpg', // ホバー画像名
    label: '西日本初出店', // ラベル
  },
  {
    name: 'FUK COFFEE Seaside', // 店名
    description: 'カフェ、軽食', // 説明
    descriptionAttention: '', // 注意文言
    link: '/the-foodhall/fuk-coffee/', // リンク先
    hvImg: 'main-fukcoffee2.jpg', // 画像名
    img: 'menu-fukcoffee.jpg', // ホバー画像名
    takeout: true,
  },
  {
    name: 'パーラーコマツ スタジアムサイド', // 店名
    description: 'レストラン・カフェバー', // 説明
    descriptionAttention: '※2024年2月29日（木）オープン', // 注意文言
    link: '/the-foodhall/parlourcomatsu/', // リンク先
    hvImg: 'main-parlourcomatsu.jpg', // 画像名
    img: 'menu-parlourcomatsu3.jpg', // ホバー画像名
  },
];

export default ShopList;
